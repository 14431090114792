import { mapGetters } from "vuex";

export default {
  name: "sales-Invoice",
  data() {
    return {
      isOpen: false,
      showDisplacement: "",
    };
  },
  props: {
    type: {
      type: String,
    },
    displacement: {
      type: Object,
      default: () => {},
    },
    showElement: {
      type: Boolean,
      default: true,
    },
    document: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      isManager: "profile/isManager",
    }),
  },
  methods: {
    openDisplacement(item) {
      this.$emit("open-displacement", item);
      this.isOpen = true;
    },
  },
};
